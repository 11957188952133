import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const excelApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    importBankHumanities: builder.mutation({
      query: (data) => ({
        url: `/api/import/bank-humanity`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    allBankHumanities: builder.query({
      query: (body) => ({
        url: `/api/get-all-bank-humanity`,
        method: 'POST',
        body: body,
      }),
      keepUnusedDataFor: 5,
    }),
    allDonorBoxes: builder.query({
      query: (body) => ({
        url: `/api/get-all-donor-boxes`,
        method: 'POST',
        body: body,
      }),
      keepUnusedDataFor: 5,
    }),
    importDonorBox: builder.mutation({
      query: (data) => ({
        url: `/api/import/donor-box`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    countData: builder.query({
      query: () => ({
        url: `/api/count-data`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useImportBankHumanitiesMutation,
  useAllBankHumanitiesQuery,
  useImportDonorBoxMutation,
  useAllDonorBoxesQuery,
  useCountDataQuery,
} = excelApiSlice;
