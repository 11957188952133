import React, { useState } from 'react';
import { useAllCategoriesQuery } from '../../slices/categoryApiSlice';
import { toast } from 'react-toastify';
import { convertTo64 } from '../../helpers/convertTo64';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useCreateCustomerMutation } from '../../slices/customersApiSlice';

const CreateUserForm = () => {
  const [first_name, setFirtsName] = useState('');
  const [last_name, setLastName] = useState('');
  const [description, setDescription] = useState('');

  let [baner_image_name, setBanerImageName] = useState('');
  const [baner_image, setBanerImage] = useState(null);
  let [facebook_image_name, setFacebookImageName] = useState('');
  const [facebook_image, setFacebookImage] = useState(null);
  let [instagram_name, setInstagramName] = useState('');
  const [instagram_image, setInstagramImage] = useState(null);
  let [nbs_qr_name, setNbsQrCode] = useState('');
  const [nbs_qr, setNbsQr] = useState(null);

  const [cus_number, setCusNumber] = useState('');
  const [cus_needed, setCusNeeded] = useState('');
  const [category_id, setCategoryId] = useState('');

  // Bank
  const [rsd_account, setRsdAccount] = useState('');
  const [other_account, setOtherAccount] = useState('');
  const [iban, setIban] = useState('');
  const [swift_bic, setSfiftBic] = useState('');

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  // Create api call
  const { data: allCategories } = useAllCategoriesQuery();

  const [createCustomer, { isLoading }] = useCreateCustomerMutation();

  // Define name for image
  let banerImage;
  let facebookImage;
  let instagramImage;
  let nbsQrName;

  const submitHandler = async (e) => {
    e.preventDefault();

    // Use image name for image
    banerImage = baner_image_name.split('\\');
    banerImage = banerImage[banerImage.length - 1].split('.');
    banerImage = banerImage[0];
    baner_image_name = banerImage;

    facebookImage = facebook_image_name.split('\\');
    facebookImage = facebookImage[facebookImage.length - 1].split('.');
    facebookImage = facebookImage[0];
    facebook_image_name = facebookImage;

    instagramImage = instagram_name.split('\\');
    instagramImage = instagramImage[instagramImage.length - 1].split('.');
    instagramImage = instagramImage[0];
    instagram_name = instagramImage;

    nbsQrName = nbs_qr_name.split('\\');
    nbsQrName = nbsQrName[nbsQrName.length - 1].split('.');
    nbsQrName = nbsQrName[0];
    nbs_qr_name = nbsQrName;

    if (category_id === '') {
      toast.error('Изаберите категорију за Корисника');
      return;
    }

    try {
      //   Call API route
      const response = await createCustomer({
        first_name,
        last_name,
        description,
        baner_image_name,
        baner_image,
        facebook_image_name,
        facebook_image,
        instagram_name,
        instagram_image,
        nbs_qr_name,
        nbs_qr,
        cus_number,
        cus_needed,
        category_id,
        rsd_account,
        other_account,
        iban,
        swift_bic,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Успешно сте додали новог Корисника');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <div className='create-form'>
      <Form onSubmit={submitHandler} className='create-form-width'>
        <Form.Group controlId='first_name' className='my-3'>
          <Form.Label>Име</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите име корисника'
            value={first_name}
            onChange={(e) => setFirtsName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='last_name' className='my-3'>
          <Form.Label>Презиме</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите презиме корисника'
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {/* <Form.Group controlId='description' className='my-3'>
          <Form.Label>O кориснику</Form.Label>
          <Form.Control
            as='textarea'
            rows={10}
            type='description'
            placeholder='Унесите текст за корисника'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control>
        </Form.Group> */}

        <Form.Group controlId='description' className='my-3'>
          <Form.Label>O кориснику</Form.Label>
          <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            value={description}
            onChange={setDescription}
          />
        </Form.Group>

        <div className='pictures-wrapper'>
          <h3>Слике</h3>
          <Form.Group controlId='baner_image_name'>
            <Form.Label>Слика за Банер (1000 x 1000)</Form.Label>
            <Form.Control
              type='file'
              value={baner_image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setBanerImageName(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setBanerImage(base);
                }
              }}
            />
          </Form.Group>
          <br />
          <Form.Group controlId='facebook_image_name'>
            <Form.Label>Фасебок Слика (1080 x 567)</Form.Label>
            <Form.Control
              type='file'
              value={facebook_image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setFacebookImageName(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setFacebookImage(base);
                }
              }}
            />
          </Form.Group>

          <br />
          <Form.Group controlId='instagram_image_name'>
            <Form.Label>Инстаграм Слика (1000 x 1000)</Form.Label>
            <Form.Control
              type='file'
              value={instagram_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setInstagramName(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setInstagramImage(base);
                }
              }}
            />
          </Form.Group>

          <br />
          <Form.Group controlId='nbs_qr_name'>
            <Form.Label>НБС КР коде (200 x 200)</Form.Label>
            <Form.Control
              type='file'
              value={nbs_qr_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setNbsQrCode(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setNbsQr(base);
                }
              }}
            />
          </Form.Group>
        </div>

        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Број Корисника</Form.Label>
          <Form.Control
            type='number'
            placeholder='Унесите број Корисника'
            value={cus_number}
            onChange={(e) => setCusNumber(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Потребан Износ</Form.Label>
          <Form.Control
            type='number'
            placeholder='Unesite potreban iznos za Korisnika'
            value={cus_needed}
            onChange={(e) => setCusNeeded(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='category_id'>
          <Form.Label>Изаберите категорију за Корисника</Form.Label>
          <Form.Select
            name='category_id'
            value={category_id}
            onChange={(e) => {
              setCategoryId(e.target.value);
            }}
          >
            <option selected>Изаберите категорију Корисника</option>
            {allCategories?.data &&
              allCategories?.data.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        {/* BANK */}
        <Form.Group controlId='rsd_account' className='my-3'>
          <Form.Label>Број динарског рачуна</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите број динарског рачуна'
            value={rsd_account}
            onChange={(e) => setRsdAccount(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='other_account' className='my-3'>
          <Form.Label>Број девизног рачуна</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите број девизног рачуна'
            value={other_account}
            onChange={(e) => setOtherAccount(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='iban' className='my-3'>
          <Form.Label>Међународни број банковног рачуна</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите међународни број банковног рачуна'
            value={iban}
            onChange={(e) => setIban(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='swift_bic' className='my-3'>
          <Form.Label>Свифт број рачуна</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите свифт број рачуна'
            value={swift_bic}
            onChange={(e) => setSfiftBic(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' className='mt-2 button-size' disabled={isLoading}>
          Додај Корисника
        </Button>

        {isLoading && <Loader />}
      </Form>
    </div>
  );
};

export default CreateUserForm;
