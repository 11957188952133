import React, { useEffect, useState } from 'react';
import {
  useAllCustomersQuery,
  useSetStoryDayMutation,
} from '../../slices/customersApiSlice';
import { Button, Pagination, Table } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Link } from 'react-router-dom';
import CreateUserForm from './CreateUserForm';
import ImportDonationForm from './ImportDonationForm';
import { toast } from 'react-toastify';
import ImportDonationFormExcel from './ImportDonationFormExcel';
import ImportDonationSmsForm from './ImportDonationSmsForm';

const UsersScreen = () => {
  const [openForm, setOpenForm] = useState(false);
  const [openImportForm, setOpenImportForm] = useState(false);
  const [openImportSmsForm, setOpenImportSmsForm] = useState(false);

  // Paginacija
  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  const body = {
    page,
    firstPage,
    nextPage,
    previousPage,
    lastPage,
    currentPage,
    perPage: 10,
  };

  // API Calls
  const {
    data: allCustomers,
    isLoading,
    error,
    refetch,
  } = useAllCustomersQuery(body);

  const [setStoryDay, { isSetStoryLoading }] = useSetStoryDayMutation();

  // Paginacija Funkcije pocetak
  useEffect(() => {
    if (allCustomers?.data) {
      setFirstPage('1');
      setPreviousPage(allCustomers?.data?.prev_page_url);
      setLastPage(allCustomers?.data?.last_page);
      setNextPage(allCustomers?.data?.next_page_url);
      setCurrentPage(allCustomers?.data?.current_page);
    }
  }, [allCustomers]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    refetch();
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    refetch();
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    refetch();
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    refetch();
  };

  const setStoryDayHandler = async (e, uid) => {
    e.preventDefault();
    try {
      // Call API route
      const response = await setStoryDay({
        uid,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Успешно сте поставили причу дана');
      }
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 1000);
      refetch();
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <main className='main-container'>
      <Button
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Додајте Корисника
      </Button>{' '}
      <Button
        onClick={() => {
          setOpenImportForm(!openImportForm);
        }}
      >
        Додајте Корисникове Донације
      </Button>{' '}
      <Button
        onClick={() => {
          setOpenImportSmsForm(!openImportSmsForm);
        }}
      >
        Додајте смс поруке
      </Button>
      {openForm && <CreateUserForm />}
      {openImportForm && <ImportDonationForm />}
      {openImportSmsForm && <ImportDonationSmsForm />}
      {/* {openImportForm && <ImportDonationFormExcel />} */}
      <br />
      <br />;
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Број корисника</th>
              <th>Име</th>
              <th>Презиме</th>
              <th>Категорија</th>
              <th>Статус</th>
              <th>Прикажи донације</th>
              <th>Прикажи калкулацијe</th>
              <th>Ажурирај Корисника</th>
              <th>Прича дана</th>
              <th>Постави за причу дана</th>
            </tr>
          </thead>
          <tbody>
            {allCustomers?.data?.data &&
              allCustomers?.data?.data.map((customer) => (
                <tr key={customer.id}>
                  <td>{customer.cus_number}</td>
                  <td>{customer.first_name}</td>
                  <td>{customer.last_name}</td>
                  <td>{customer.category.name}</td>
                  <td>{customer.is_active === 1 ? 'Aktivan' : 'Neaktivan'}</td>
                  <td className='table-td-centered'>
                    <Link
                      to={`/korisnici/donacije/${customer.cus_number}`}
                      state={{ customer: customer }}
                    >
                      <Button variant='info'>Донације</Button>
                    </Link>
                  </td>
                  <td className='table-td-centered'>
                    <Link
                      to={`/korisnici/kalkulacije/${customer.cus_number}`}
                      state={{ customer: customer }}
                    >
                      <Button variant='info'>Калкулације</Button>
                    </Link>
                  </td>
                  <td className='table-td-centered'>
                    <Link
                      to={`/korisnici/${customer.id}`}
                      state={{ customer: customer }}
                    >
                      <Button variant='info'>Ажурирај</Button>
                    </Link>
                  </td>
                  <td className='table-td-centered'>
                    {customer.story_day === 1 ? 'Јесте' : 'Није'}
                  </td>
                  <td className='table-td-centered'>
                    <Button
                      variant='success'
                      onClick={(e) => {
                        setStoryDayHandler(e, customer.id);
                      }}
                    >
                      Постави
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Pagination className='justify-content-center mb-4 my-5'>
        <Pagination.First onClick={paginationFirstHandler} />
        <Pagination.Prev onClick={paginationPrevHandler} />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item disabled>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={paginationNextHandler} />
        <Pagination.Last onClick={paginationLastHandler} />
      </Pagination>
    </main>
  );
};

export default UsersScreen;
