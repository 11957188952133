import { BsPeopleFill, BsBank, BsFillBoxFill } from 'react-icons/bs';
import { useCountDataQuery } from '../slices/excelApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { BiSolidCategory } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

const HomeScreen = () => {
  // Call API
  const { data: countData, isLoading, error } = useCountDataQuery();

  const { adminInfo } = useSelector((state) => state.adminInfo);

  const token = jwtDecode(adminInfo?.data);

  useEffect(() => {
    let timeNow = new Date();
    let epochTimeToken = token?.exp * 1000;
    let tokenDate = new Date(epochTimeToken);

    let tokenDateCheck = new Date(tokenDate);
    let timeNowCheck = new Date(timeNow);
    if (timeNowCheck > tokenDateCheck) {
      localStorage.removeItem('adminInfo');
      localStorage.clear();
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }
  }, [token]);

  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>Почетна страна</h3>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <div className='main-cards'>
          <div className='card'>
            <div className='card-inner'>
              <h3>Категорије</h3>
              <BiSolidCategory className='icon' />
            </div>
            <h1>{countData?.data?.categories}</h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3>Банке Хуманости</h3>
              <BsBank className='card_icon' />
            </div>
            <h1>{countData?.data?.bank_humanities}</h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3>Донаторске Кутије</h3>
              <BsFillBoxFill className='card_icon' />
            </div>
            <h1>{countData?.data?.donor_boxes}</h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3>Корисници</h3>
              <BsPeopleFill className='card_icon' />
            </div>
            <h1>{countData?.data?.customer}</h1>
          </div>
        </div>
      )}
    </main>
  );
};

export default HomeScreen;
