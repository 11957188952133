import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoute';
import LoginScreen from './screens/auth/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import AllCategoriesScreen from './screens/category/AllCategoriesScreen';
import UpdateCategoryScreen from './screens/category/UpdateCategoryScreen';
import ExcelScreen from './screens/excel/ExcelScreen';
import BankScreen from './screens/excel/BankScreen';
import DonorBoxScreen from './screens/excel/DonorBoxScreen';
import UsersScreen from './screens/users/UsersScreen';
import DonationScreen from './screens/users/DonationScreen';
import CalculationScreen from './screens/users/CalculationScreen';
import UpdateUserScreen from './screens/users/UpdateUserScreen';
import OrdersScreen from './screens/orders/OrdersScreen';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/prijavi-se' element={<LoginScreen />} />
      <Route path='' element={<PrivateRoute />}>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/kategorije' element={<AllCategoriesScreen />} />
        <Route path='/kategorije/:id' element={<UpdateCategoryScreen />} />
        <Route path='/excel' element={<ExcelScreen />} />
        <Route path='/banke-humanosti' element={<BankScreen />} />
        <Route path='/donorske-kutije' element={<DonorBoxScreen />} />
        <Route path='/korisnici' element={<UsersScreen />} />
        <Route path='/korisnici/:id' element={<UpdateUserScreen />} />
        <Route
          path='/korisnici/donacije/:cus_number'
          element={<DonationScreen />}
        />
        <Route
          path='/korisnici/kalkulacije/:cus_number'
          element={<CalculationScreen />}
        />
        <Route path='/transakcije' element={<OrdersScreen />} />
      </Route>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
