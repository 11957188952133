import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useAdminLoginMutation } from '../../slices/usersApiSlice';
import { setCredentials } from '../../slices/authSlice';
import { toast } from 'react-toastify';
import pokreniZivotLogo from '../../assets/pokrenizivot.png';

const LoginScreen = () => {
  // Declare state variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Decleare side function
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Create api call
  const [adminLogin, { isLoading }] = useAdminLoginMutation();

  // use adminInfo state
  const { adminInfo } = useSelector((state) => state?.adminInfo);

  // UseEffect if we already loged in
  useEffect(() => {
    if (adminInfo) {
      navigate('/');
    }
  }, [adminInfo, navigate]);

  // Create submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      // Call API route
      const response = await adminLogin({ email, password }).unwrap();
      dispatch(setCredentials({ ...response }));
      navigate('/');
      window.location.reload(false);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data || error?.error);
      }

      if (error?.status === 400) {
        toast.error(error?.data?.data || error?.error);
      }
    }
  };

  return (
    <main className='main-container-login'>
      <img src={pokreniZivotLogo} alt='АДМИН' className='icon_header' />
      <h1>Пријавите се</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email' className='my-3'>
          <Form.Label>Унесите Е-маил адресу</Form.Label>
          <Form.Control
            type='email'
            placeholder='Унесите Е-маил адресу'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password' className='my-3'>
          <Form.Label>Унесите Шифру</Form.Label>
          <Form.Control
            type='password'
            placeholder='Унесите Шифру'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type='submit' className='mt-2' disabled={isLoading}>
          Пријавите се
        </Button>
      </Form>
    </main>
  );
};

export default LoginScreen;
