import axios from 'axios';

async function getLongLat(addressObject) {
  console.log('LONG LAT', addressObject);
  const addressString = `${addressObject.address}, ${addressObject.city}, ${addressObject.municipality}`;

  try {
    // const response = await axios.get(
    //   `https://nominatim.openstreetmap.org/search?format=json&q=${addressObject.address}`
    // );

    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        addressString
      )}`
    );

    if (response.data && response.data.length > 0) {
      const firstResult = response.data[0];
      const latitude = firstResult.lat;
      const longitude = firstResult.lon;
      // console.log('latitude ', latitude, ' longitude ', longitude);
      return { latitude, longitude };
    } else {
      // console.log('Nema rezultata za adresu.');
      return null;
    }
  } catch (error) {
    // console.error('Greška pri dobijanju koordinata:', error);
    return null;
  }
}

export default getLongLat;
