import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from '../../slices/categoryApiSlice';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/Loader';

const UpdateCategoryScreen = () => {
  const [name, setName] = useState('');
  const { id: uid } = useParams();

  const location = useLocation();
  const slug = location?.state?.slug;

  const { data: getCategory } = useGetCategoryQuery({ slug });
  const [updateCategory, { isLoading }] = useUpdateCategoryMutation();

  useEffect(() => {
    if (getCategory?.data) {
      setName(getCategory?.data?.name);
    }
  }, [getCategory]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      // Call API route
      const response = await updateCategory({
        uid,
        name,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Успешно сте ажурирали Категорију');
      }
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link to='/kategorije'>
        <Button>Назад</Button>
      </Link>
      <br />
      <br />
      <div className='create-form'>
        <Form onSubmit={submitHandler} className='create-form-width'>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Назив Категорије</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите назив Категорије'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button
            type='submit'
            className='mt-2 button-size'
            disabled={isLoading}
          >
            Ажурирај Категорију
          </Button>

          {isLoading && <Loader />}
        </Form>
      </div>
    </main>
  );
};

export default UpdateCategoryScreen;
