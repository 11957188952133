import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const customersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allCustomers: builder.query({
      query: (body) => ({
        url: `/api/customers`,
        method: 'POST',
        body: body,
      }),
      keepUnusedDataFor: 5,
    }),
    allCustomerDonations: builder.query({
      query: (customer_id) => ({
        url: `/api/customers/donations`,
        method: 'POST',
        body: customer_id,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    allCustomerCalculations: builder.query({
      query: (customer_id) => ({
        url: `/api/customers/calculation`,
        method: 'POST',
        body: customer_id,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createCustomer: builder.mutation({
      query: (data) => ({
        url: `/api/customers/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    importCustomerDonation: builder.mutation({
      query: (data) => ({
        url: `/api/import/customer-transaction`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    showCustomer: builder.query({
      query: (uid) => ({
        url: `/api/customers/show`,
        method: 'POST',
        body: uid,
      }),
      keepUnusedDataFor: 5,
    }),
    updateCustomer: builder.mutation({
      query: (data) => ({
        url: `/api/customers/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    setStoryDay: builder.mutation({
      query: (uid) => ({
        url: `/api/customers/add-story-day`,
        method: 'POST',
        body: uid,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    importCustomerSms: builder.mutation({
      query: (data) => ({
        url: `/api/import/sms`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useAllCustomersQuery,
  useAllCustomerDonationsQuery,
  useCreateCustomerMutation,
  useShowCustomerQuery,
  useUpdateCustomerMutation,
  useAllCustomerCalculationsQuery,
  useImportCustomerDonationMutation,
  useSetStoryDayMutation,
  useImportCustomerSmsMutation,
} = customersApiSlice;
