import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  useShowCustomerQuery,
  useUpdateCustomerMutation,
} from '../../slices/customersApiSlice';
import { Button, Form } from 'react-bootstrap';
import { convertTo64 } from '../../helpers/convertTo64';
import { useAllCategoriesQuery } from '../../slices/categoryApiSlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UpdateUserScreen = () => {
  const { id: uid } = useParams();

  const location = useLocation();
  const customer = location?.state?.customer;

  const [first_name, setFirtsName] = useState('');
  const [last_name, setLastName] = useState('');
  const [description, setDescription] = useState('');

  let [baner_image_name, setBanerImageName] = useState('');
  const [baner_image, setBanerImage] = useState(null);
  const [banerImagePreview, setBanerImagePreview] = useState('');

  let [facebook_image_name, setFacebookImageName] = useState('');
  const [facebook_image, setFacebookImage] = useState(null);
  const [facebookImagePreview, setFacebookImagePreview] = useState('');

  let [instagram_name, setInstagramName] = useState('');
  const [instagram_image, setInstagramImage] = useState(null);
  const [instagramImagePreview, setInstagramImagePreview] = useState('');

  let [nbs_qr_name, setNbsQrCode] = useState('');
  const [nbs_qr, setNbsQr] = useState(null);
  const [nbsImagePreview, setNbsImagePreview] = useState('');

  const [cus_number, setCusNumber] = useState('');
  const [cus_needed, setCusNeeded] = useState('');
  const [category_id, setCategoryId] = useState('');

  const [is_active, setActive] = useState('');

  // Bank
  const [rsd_account, setRsdAccount] = useState('');
  const [other_account, setOtherAccount] = useState('');
  const [iban, setIban] = useState('');
  const [swift_bic, setSfiftBic] = useState('');

  const { data: showCustomer } = useShowCustomerQuery({
    slug: customer.slug_eng,
  });
  const { data: allCategories } = useAllCategoriesQuery();

  useEffect(() => {
    if (showCustomer?.data) {
      setFirtsName(showCustomer?.data?.first_name);
      setLastName(showCustomer?.data?.last_name);
      // setDescription(showCustomer?.data?.description);
      setDescription(JSON.parse(showCustomer?.data?.description));
      setBanerImagePreview(showCustomer?.data?.baner);
      setFacebookImagePreview(showCustomer?.data?.facebook_image);
      setInstagramImagePreview(showCustomer?.data?.instagram_image);
      setNbsImagePreview(showCustomer?.data?.nbs_qr);
      setCusNumber(showCustomer?.data?.cus_number);
      setCusNeeded(showCustomer?.data?.cus_needed);
      setCategoryId(showCustomer?.data?.category_id);
      setActive(showCustomer?.data?.is_active);
      setRsdAccount(showCustomer?.data?.rsd_account);
      setOtherAccount(showCustomer?.data?.other_account);
      setIban(showCustomer?.data?.iban);
      setSfiftBic(showCustomer?.data?.swift_bic);
    }
  }, [showCustomer]);

  const [updateCustomer, { isLoading }] = useUpdateCustomerMutation();

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  // Define name for image
  let banerImage;
  let facebookImage;
  let instagramImage;
  let nbsQrName;

  const submitHandler = async (e) => {
    e.preventDefault();

    // Use image name for image
    banerImage = baner_image_name.split('\\');
    banerImage = banerImage[banerImage.length - 1].split('.');
    banerImage = banerImage[0];
    baner_image_name = banerImage;

    facebookImage = facebook_image_name.split('\\');
    facebookImage = facebookImage[facebookImage.length - 1].split('.');
    facebookImage = facebookImage[0];
    facebook_image_name = facebookImage;

    instagramImage = instagram_name.split('\\');
    instagramImage = instagramImage[instagramImage.length - 1].split('.');
    instagramImage = instagramImage[0];
    instagram_name = instagramImage;

    nbsQrName = nbs_qr_name.split('\\');
    nbsQrName = nbsQrName[nbsQrName.length - 1].split('.');
    nbsQrName = nbsQrName[0];
    nbs_qr_name = nbsQrName;

    try {
      // Call API route
      const response = await updateCustomer({
        uid,
        first_name,
        last_name,
        description,
        baner_image_name,
        baner_image,
        facebook_image_name,
        facebook_image,
        instagram_name,
        instagram_image,
        nbs_qr_name,
        nbs_qr,
        cus_number,
        cus_needed,
        category_id,
        is_active,
        rsd_account,
        other_account,
        iban,
        swift_bic,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Успешно сте ажурирали Корисника');
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      }
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link to='/korisnici'>
        <Button> Назад</Button>
      </Link>
      <br />
      <br />
      <div className='create-form'>
        <Form onSubmit={submitHandler} className='create-form-width'>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Име</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите име корисника'
              value={first_name}
              onChange={(e) => setFirtsName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Презиме</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите презиме корисника'
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='description' className='my-3'>
            <Form.Label>O кориснику</Form.Label>
            {/* <Form.Control
              as='textarea'
              rows={10}
              type='description'
              placeholder='Унесите текст за корисника'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Form.Control> */}
            <ReactQuill
              theme='snow'
              modules={modules}
              formats={formats}
              value={description}
              onChange={setDescription}
            />
          </Form.Group>

          <Form.Group controlId='baner_image_name'>
            <Form.Label>Слика за Банер (1000 x 1000)</Form.Label>
            <Form.Control
              type='file'
              value={baner_image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setBanerImageName(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setBanerImage(base);
                }
              }}
            />
          </Form.Group>
          <br />
          {banerImagePreview && (
            <img
              style={{ width: '400px' }}
              src={`/api/show-file/${banerImagePreview}`}
              alt='Card'
            />
          )}
          <br />
          <Form.Group controlId='facebook_image_name'>
            <Form.Label>Фасебок Слика (1080 x 567)</Form.Label>
            <Form.Control
              type='file'
              value={facebook_image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setFacebookImageName(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setFacebookImage(base);
                }
              }}
            />
          </Form.Group>
          <br />
          {facebookImagePreview && (
            <img
              style={{ width: '400px' }}
              src={`/api/show-file/${facebookImagePreview}`}
              alt='Card'
            />
          )}

          <br />
          <Form.Group controlId='instagram_image_name'>
            <Form.Label>Инстаграм Слика (1000 x 1000)</Form.Label>
            <Form.Control
              type='file'
              value={instagram_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setInstagramName(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setInstagramImage(base);
                }
              }}
            />
          </Form.Group>
          <br />
          {instagramImagePreview && (
            <img
              style={{ width: '400px' }}
              src={`/api/show-file/${instagramImagePreview}`}
              alt='Card'
            />
          )}

          <br />
          <Form.Group controlId='nbs_qr_name'>
            <Form.Label>НБС КР коде (200 x 200)</Form.Label>
            <Form.Control
              type='file'
              value={nbs_qr_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setNbsQrCode(imageName);
                if (e.target.files[0].size > 5000000) {
                  toast.error('Слика мора бити испод 5 мб');
                } else {
                  const base = await convertTo64(e.target.files[0]);
                  setNbsQr(base);
                }
              }}
            />
          </Form.Group>
          <br />
          {nbsImagePreview && (
            <img
              style={{ width: '400px' }}
              src={`/api/show-file/${nbsImagePreview}`}
              alt='Card'
            />
          )}

          <br />

          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Број Корисника</Form.Label>
            <Form.Control
              type='number'
              placeholder='Унесите број Корисника'
              value={cus_number}
              onChange={(e) => setCusNumber(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Потребан Износ</Form.Label>
            <Form.Control
              type='number'
              placeholder='Унесите потребан износ за Корисника'
              value={cus_needed}
              onChange={(e) => setCusNeeded(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='category_id'>
            <Form.Label>Изаберите категорију за Корисника</Form.Label>
            <Form.Select
              name='category_id'
              value={category_id}
              onChange={(e) => {
                setCategoryId(e.target.value);
              }}
            >
              {allCategories?.data &&
                allCategories?.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId='is_active'>
            <Form.Label>Статус корисника</Form.Label>
            <Form.Select
              name='is_active'
              value={is_active}
              onChange={(e) => {
                setActive(e.target.value);
              }}
            >
              <option value={1}>Активан</option>
              <option value={0}>Неактиван</option>
            </Form.Select>
          </Form.Group>

          {/* BANK */}
          <Form.Group controlId='rsd_account' className='my-3'>
            <Form.Label>Број динарског рачуна</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите број динарског рачуна'
              value={rsd_account}
              onChange={(e) => setRsdAccount(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='other_account' className='my-3'>
            <Form.Label>Број девизног рачуна</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите број девизног рачуна'
              value={other_account}
              onChange={(e) => setOtherAccount(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='iban' className='my-3'>
            <Form.Label>Међународни број банковног рачуна</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите међународни број банковног рачуна'
              value={iban}
              onChange={(e) => setIban(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='swift_bic' className='my-3'>
            <Form.Label>Свифт број рачуна</Form.Label>
            <Form.Control
              type='text'
              placeholder='Унесите свифт број рачуна'
              value={swift_bic}
              onChange={(e) => setSfiftBic(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button
            type='submit'
            className='mt-2 button-size'
            disabled={isLoading}
          >
            Ажурирај Корисника
          </Button>

          {isLoading && <Loader />}
        </Form>
      </div>
    </main>
  );
};

export default UpdateUserScreen;
