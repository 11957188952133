import { apiSlice } from "./apiSlice";

const adminInfo = localStorage.getItem("adminInfo")
  ? JSON.parse(localStorage.getItem("adminInfo"))
  : null;

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    orders: builder.query({
      query: (body) => ({
        url: `/api/orders`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),

      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useOrdersQuery } = ordersApiSlice;
