import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import {
  useAllCategoriesQuery,
  useRemoveCategoryMutation,
} from '../../slices/categoryApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Link } from 'react-router-dom';
import CreateCategoryForm from './CreateCategoryForm';
import ConfirmModal from '../../components/ConfirmModal';

const AllCategoriesScreen = () => {
  const [openForm, setOpenForm] = useState(false);

  // API Calls
  const {
    data: allCategories,
    isLoading,
    error,
    refetch,
  } = useAllCategoriesQuery();

  const [removeCategory] = useRemoveCategoryMutation();

  return (
    <main className='main-container'>
      <Button
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Додај нову Категорију
      </Button>
      {openForm && <CreateCategoryForm />}
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Назив Категорије</th>
              <th>Ажурирај Категорију</th>
              <th>Обриши Категорију</th>
            </tr>
          </thead>
          <tbody>
            {allCategories?.data &&
              allCategories?.data.map((category, index) => (
                <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{category.name}</td>
                  <td className='table-td-centered'>
                    <Link
                      to={`/kategorije/${category.id}`}
                      state={{ slug: category.slug_eng }}
                    >
                      <Button variant='info'>Ажурирај</Button>
                    </Link>
                  </td>
                  <td className='table-td-centered'>
                    <ConfirmModal
                      id={category?.id}
                      refetch={refetch}
                      deleteFunc={removeCategory}
                      deleteMessage='Успешно сте обрисали Категорију!'
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default AllCategoriesScreen;
