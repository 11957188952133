import React, { useEffect, useState } from 'react';
import { Button, Pagination, Table } from 'react-bootstrap';
import BankForm from './BankForm';
import { useAllBankHumanitiesQuery } from '../../slices/excelApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import BankFormExcel from './BankFormExcel';

const BankScreen = () => {
  const [openForm, setOpenForm] = useState(false);

  // Paginacija
  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  const body = {
    page,
    firstPage,
    nextPage,
    previousPage,
    lastPage,
    currentPage,
    perPage: 10,
  };

  // API Calls
  const {
    data: allBankHumanities,
    isLoading,
    error,
    refetch,
  } = useAllBankHumanitiesQuery(body);

  // Paginacija Funkcije pocetak
  useEffect(() => {
    if (allBankHumanities?.data) {
      setFirstPage('1');
      setPreviousPage(allBankHumanities?.data?.prev_page_url);
      setLastPage(allBankHumanities?.data?.last_page);
      setNextPage(allBankHumanities?.data?.next_page_url);
      setCurrentPage(allBankHumanities?.data?.current_page);
    }
  }, [allBankHumanities]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    refetch();
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    refetch();
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    refetch();
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    refetch();
  };

  // Paginacija Funkcije Kraj

  return (
    <main className='main-container'>
      <Button
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Додајте Банке Хуманости
      </Button>
      {/* {openForm && <BankForm />} */}
      {openForm && <BankFormExcel />}
      <br />
      <br />;
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Јединствени број</th>
              <th>Стари број</th>
              <th>Назив Објекта</th>
              <th>Адреса</th>
              <th>Град</th>
              <th>Општина</th>
            </tr>
          </thead>
          <tbody>
            {allBankHumanities?.data?.data &&
              allBankHumanities?.data?.data.map((bank, index) => (
                <tr key={bank.id}>
                  <td>{index + 1}</td>
                  <td>{bank.unique_number}</td>
                  <td>{bank.old_number}</td>
                  <td>{bank.object_name}</td>
                  <td>{bank.address}</td>
                  <td>{bank.city}</td>
                  <td>{bank.municipality}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Pagination className='justify-content-center mb-4 my-5'>
        <Pagination.First onClick={paginationFirstHandler} />
        <Pagination.Prev onClick={paginationPrevHandler} />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item disabled>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={paginationNextHandler} />
        <Pagination.Last onClick={paginationLastHandler} />
      </Pagination>
    </main>
  );
};

export default BankScreen;
