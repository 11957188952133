import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <Spinner
      as='span'
      animation='border'
      size='sm'
      role='status'
      aria-hidden='true'
      style={{
        color: 'black',
        width: '100px',
        height: '100px',
        margin: 'auto',
        display: 'block',
      }}
    />
  );
};

export default Loader;
