import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translate files
import lat from './locales/lat/translation.json';
import cir from './locales/cir/translation.json';
import en from './locales/en/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    lat: {
      translation: lat,
    },
    cir: {
      translation: cir,
    },
    en: {
      translation: en,
    },
  },
  lng: 'lat', // Početni jezik
  fallbackLng: 'lat', // Jezik koji će se koristiti ako traženi jezik nije dostupan
  interpolation: {
    escapeValue: false, // Između ostalog, korisno za JSX interpolaciju
  },
});

export default i18n;
