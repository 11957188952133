function changeCreatedAt(pretvoriDatum) {
  const dateCreated = new Date(pretvoriDatum);

  const day = dateCreated.getDate().toString().padStart(2, "0");
  const month = (dateCreated.getMonth() + 1).toString().padStart(2, "0");
  const year = dateCreated.getFullYear();
  const hour = dateCreated.getHours().toString().padStart(2, "0");
  const minut = dateCreated.getMinutes().toString().padStart(2, "0");
  const second = dateCreated.getSeconds().toString().padStart(2, "0");

  const result = `${day}.${month}.${year}. ${hour}:${minut}`;

  return result;
}

export default changeCreatedAt;
