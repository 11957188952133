import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { useCreateCategoryMutation } from '../../slices/categoryApiSlice';
import { toast } from 'react-toastify';

const CreateCategoryForm = () => {
  const [name, setName] = useState('');

  // Create api call
  const [createCategory, { isLoading }] = useCreateCategoryMutation();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      // Call API route
      const response = await createCategory({
        name,
      }).unwrap();
      if (response?.slug_eng) {
        toast.success('Успешно сте додали нову Категорију');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <div className='create-form'>
      <Form onSubmit={submitHandler} className='create-form-width'>
        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Назив Категорије</Form.Label>
          <Form.Control
            type='text'
            placeholder='Унесите назив Категорије'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' className='mt-2 button-size' disabled={isLoading}>
          Додај категорију
        </Button>

        {isLoading && <Loader />}
      </Form>
    </div>
  );
};

export default CreateCategoryForm;
