import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    allCategories: builder.query({
      query: () => ({
        url: `/api/categories`,
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
    }),
    createCategory: builder.mutation({
      query: (data) => ({
        url: `/api/categories/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getCategory: builder.query({
      query: (slug) => ({
        url: `/api/categories/show`,
        method: 'POST',
        body: slug,
      }),
      keepUnusedDataFor: 5,
    }),
    updateCategory: builder.mutation({
      query: (data) => ({
        url: `/api/categories/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeCategory: builder.mutation({
      query: (id) => ({
        url: `/api/categories/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useAllCategoriesQuery,
  useCreateCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
  useRemoveCategoryMutation,
} = categoryApiSlice;
