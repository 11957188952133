import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ConfirmModal = ({ id, refetch, deleteFunc, deleteMessage }) => {
  const [show, setShow] = useState(false);

  const handleCloseNo = () => setShow(false);

  const handleCloseYes = () => {
    setShow(false);
    removeHandler();
  };
  const handleShow = () => setShow(true);

  const removeHandler = async () => {
    try {
      const response = await deleteFunc(id);
      if (response?.data?.status === 'success') {
        toast.success(deleteMessage);
        refetch();
      } else {
        toast.error(response?.error?.data?.data);
      }
    } catch (err) {
      toast.error(err?.data?.data || err?.data);
    }
  };

  return (
    <>
      <Button variant='danger' onClick={handleShow}>
      Обриши
      </Button>

      <Modal show={show} onHide={handleCloseNo} backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>Покрени Живот</Modal.Title>
        </Modal.Header>
        <Modal.Body>Да ли сте сигурни?</Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleCloseNo}>
          Не
          </Button>
          <Button variant='success' onClick={handleCloseYes}>
          Да
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
