import React from 'react';
import { BsBank, BsFillBoxFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const ExcelScreen = () => {
  return (
    <main className='main-container'>
      <div className='main-cards'>
        <div className='card'>
          <Link
            to='/banke-humanosti'
            style={{ textDecoration: 'None', color: 'white' }}
          >
            <div className='card-inner'>
              <h3>Банке Хуманости</h3>

              <BsBank className='card_icon' />
            </div>
          </Link>
        </div>

        <div className='card'>
          <Link
            to='/donorske-kutije'
            style={{ textDecoration: 'None', color: 'white' }}
          >
            <div className='card-inner'>
              <h3>Донаторске Кутије</h3>
              <BsFillBoxFill className='card_icon' />
            </div>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default ExcelScreen;
