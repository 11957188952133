import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BsGrid1X2Fill,
  BsPeopleFill,
  BsFillHouseLockFill,
  BsBank2,
  BsFileEarmarkExcelFill,
} from 'react-icons/bs';
import { BiSolidCategory } from 'react-icons/bi';
import pokreniZivotLogo from '../assets/pokrenizivot.png';

export const Sidebar = ({ openSidebarToggle, OpenSidebar }) => {
  // const { t } = useTranslation();
  const { t, i18n } = useTranslation();

  return (
    <aside
      id='sidebar'
      className={openSidebarToggle ? 'sidebar-responsive' : ''}
    >
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          {/* <BsFillHouseLockFill className='icon_header' /> АДМИН */}
          <img
            src={pokreniZivotLogo}
            alt='АДМИН'
            className='icon_header'
          />{' '}
        </div>
        <span className='icon close_icon' onClick={OpenSidebar}>
          X
        </span>
      </div>

      <ul className='sidebar-list'>
        <Link to='/' className='linkDom'>
          <li className='sidebar-list-item'>
            <BsGrid1X2Fill className='icon' /> Почетна страна
          </li>
        </Link>

        <Link to='/kategorije' className='linkDom'>
          <li className='sidebar-list-item'>
            <BiSolidCategory className='icon' /> Категорије
          </li>
        </Link>

        <Link to='/excel' className='linkDom'>
          <li className='sidebar-list-item'>
            <BsFileEarmarkExcelFill className='icon' /> Еxцел
          </li>
        </Link>

        <Link to='/korisnici' className='linkDom'>
          <li className='sidebar-list-item'>
            <BsPeopleFill className='icon' /> Корисници
          </li>
        </Link>

        <Link to='/transakcije' className='linkDom'>
          <li className='sidebar-list-item'>
            <BsBank2 className='icon' /> Трансакције
          </li>
        </Link>

        {/* <li className='sidebar-list-item'>
          <BsBank2 className='icon' /> Трансакције
        </li> */}
      </ul>
    </aside>
  );
};
