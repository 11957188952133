import React, { useEffect, useState } from 'react';
import { useAllCustomerDonationsQuery } from '../../slices/customersApiSlice';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Button, Pagination, Table } from 'react-bootstrap';

const DonationScreen = () => {
  const { cus_number: customer_id } = useParams();

  // Paginacija
  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  const body = {
    customer_id,
    page,
    firstPage,
    nextPage,
    previousPage,
    lastPage,
    currentPage,
    perPage: 10,
  };

  // API Calls
  const {
    data: allCustomerDonations,
    isLoading,
    error,
    refetch,
  } = useAllCustomerDonationsQuery(body);

  // Paginacija Funkcije pocetak
  useEffect(() => {
    if (allCustomerDonations?.data) {
      setFirstPage('1');
      setPreviousPage(allCustomerDonations?.data?.prev_page_url);
      setLastPage(allCustomerDonations?.data?.last_page);
      setNextPage(allCustomerDonations?.data?.next_page_url);
      setCurrentPage(allCustomerDonations?.data?.current_page);
    }
  }, [allCustomerDonations]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    refetch();
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    refetch();
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    refetch();
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    refetch();
  };

  return (
    <main className='main-container'>
      <Link to='/korisnici'>
        <Button> Назад</Button>
      </Link>
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Јединствени број</th>
              <th>Име и Презиме</th>
              <th>Износ</th>
              <th>Валута</th>
              <th>Датум</th>
            </tr>
          </thead>
          <tbody>
            {allCustomerDonations?.data?.data &&
              allCustomerDonations?.data?.data.map((donation, index) => (
                <tr key={donation.id}>
                  <td>{index + 1}</td>
                  <td>{donation.unique_number}</td>
                  <td>{donation.full_name}</td>
                  <td>{donation.amount}</td>
                  <td>{donation.currency}</td>
                  <td>{donation.date_of_payment}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Pagination className='justify-content-center mb-4 my-5'>
        <Pagination.First onClick={paginationFirstHandler} />
        <Pagination.Prev onClick={paginationPrevHandler} />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item disabled>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={paginationNextHandler} />
        <Pagination.Last onClick={paginationLastHandler} />
      </Pagination>
    </main>
  );
};

export default DonationScreen;
