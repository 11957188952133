import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { Pagination, Table } from "react-bootstrap";
import { useOrdersQuery } from "../../slices/ordersApiSlice";
import changeCreatedAt from "../../helpers/convertCreatedAt";

const OrdersScreen = () => {
  // Paginacija
  const [page, setPage] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [previousPage, setPreviousPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [currentPage, setCurrentPage] = useState("");

  const body = {
    page,
    firstPage,
    nextPage,
    previousPage,
    lastPage,
    currentPage,
    perPage: 10,
  };

  // API Calls
  const { data: orders, isLoading, error, refetch } = useOrdersQuery(body);

  // Paginacija Funkcije pocetak
  useEffect(() => {
    if (orders?.data) {
      setFirstPage("1");
      setPreviousPage(orders?.data?.prev_page_url);
      setLastPage(orders?.data?.last_page);
      setNextPage(orders?.data?.next_page_url);
      setCurrentPage(orders?.data?.current_page);
    }
  }, [orders]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    refetch();
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split("=")[1];
      setPage(page);
    }
    refetch();
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split("=")[1];
      setPage(page);
    }
    refetch();
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    refetch();
  };

  return (
    <main className="main-container">
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Име и Презиме</th>
              <th>Е-мејл</th>
              <th>Валута</th>
              <th>Анонимни донатор</th>
              <th>Клијент</th>
              <th>Број Клијента</th>
              <th>Износ</th>
              <th>Датум трансакције</th>
            </tr>
          </thead>
          <tbody>
            {orders?.data?.data &&
              orders?.data?.data.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>{order?.full_name}</td>
                  <td>{order?.email}</td>
                  <td>{order?.currency}</td>
                  <td>{order?.anonymous_donor === "yes" ? "да" : "не"}</td>
                  <td>
                    {order?.customer?.first_name} {order?.customer?.last_name}
                  </td>
                  <td>{order?.customer?.cus_number}</td>
                  <td>
                    {Number(order?.amount).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td>{changeCreatedAt(order?.created_at)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Pagination className="justify-content-center mb-4 my-5">
        <Pagination.First onClick={paginationFirstHandler} />
        <Pagination.Prev onClick={paginationPrevHandler} />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item disabled>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={paginationNextHandler} />
        <Pagination.Last onClick={paginationLastHandler} />
      </Pagination>
    </main>
  );
};

export default OrdersScreen;
