import { apiSlice } from './apiSlice';

export const adminAuthSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    adminLogin: builder.mutation({
      query: (data) => ({
        url: `/api/login`,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 5,
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/api/forgot-password`,
        method: 'POST',
        body: data,
      }),
    }),
    changeForgotPassword: builder.mutation({
      query: (data) => ({
        url: `/api/change-forgot-password`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useForgotPasswordMutation,
  useChangeForgotPasswordMutation,
} = adminAuthSlice;
