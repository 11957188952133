import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import * as XLSX from "xlsx";
import { useImportBankHumanitiesMutation } from "../../slices/excelApiSlice";
import { toast } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import getLongLat from "../../helpers/getLongLat";

const BankFormExcel = () => {
  const [excelData, setExcelData] = useState(null);
  const [nominativLoad, setNominativLoad] = useState(false);

  const [importBankHumanities, { isLoading }] =
    useImportBankHumanitiesMutation();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const excelData = XLSX.utils.sheet_to_json(firstSheet, {
          header: 1,
          range: 1,
        });

        const filteredData = excelData.filter((row) => row.length > 0);

        setExcelData(filteredData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const body = [];
    if (excelData) {
      setNominativLoad(true);
      for (const e of excelData) {
        let bankObj = {
          uid: e[0] ? e[0] : null,
          old_number: e[1] ? e[1] : null,
          objectName: e[3] ? e[3] : null,
          address: e[4] ? e[4] : null,
          city: e[5] ? e[5] : null,
          municipality: e[6] ? e[6] : null,
        };

        // Nominative
        let a = await getLongLat({
          address: bankObj.address,
          city: bankObj.city,
          municipality: bankObj.municipality,
        });

        if (a?.latitude && a?.longitude) {
          bankObj.latitude = a?.latitude;
          bankObj.longitude = a?.longitude;
        } else {
          bankObj.latitude = null;
          bankObj.longitude = null;
        }

        body.push(bankObj);
      }
      setNominativLoad(false);
    }

    try {
      // Call API route
      const response = await importBankHumanities(body).unwrap();
      if (response?.status === "success") {
        toast.success("Успешно сте додали Еxцел Фајл");
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <div className="create-form">
      <Form onSubmit={submitHandler} className="create-form-width">
        <Form.Group controlId="formBasicFile">
          <Form.Label>Изаберите Еxцел фајл (.xls, .xlsx)</Form.Label>
          <Form.Control
            required
            type="file"
            accept=".xls, .xlsx"
            name="file"
            onChange={handleFileUpload}
          />
        </Form.Group>

        <Button type="submit" className="mt-2 button-size" disabled={isLoading}>
          Додај Банке Хуманости
        </Button>

        {(isLoading || nominativLoad) && <Loader />}
      </Form>
    </div>
  );
};

export default BankFormExcel;
