import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { useImportCustomerDonationMutation } from "../../slices/customersApiSlice";
import Message from "../../components/Message";

const ImportDonationForm = () => {
  const [importCustomerDonation, { isLoading }] =
    useImportCustomerDonationMutation();

  const [formData, setFormData] = useState(new FormData());

  const [exclelError, setExcelError] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formData.set("import_customers", file);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setExcelError(null);
    try {
      // Call API route
      const response = await importCustomerDonation(formData).unwrap();
      if (response?.status === "success") {
        toast.success("Успешно сте додали Еxцел Фајл");
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        // toast.error(
        //   `Овај Уид: ${error?.data?.uid} није добар проверите у Excel за датум: ${error?.data?.date_of_payment} `
        // );
        setExcelError(
          `Овај Уид: ${error?.data?.uid} за корисника ${error?.data?.cus_number}  није добар проверите у Excel овај датум: ${error?.data?.date_of_payment} `
        );
      }
    }
  };

  return (
    <div className="create-form">
      <Form onSubmit={submitHandler} className="create-form-width">
        <Form.Group controlId="formBasicFile">
          <Form.Label>Изаберите Еxцел фајл (.xls, .xlsx)</Form.Label>
          <Form.Control
            required
            type="file"
            accept=".xls, .xlsx"
            name="file"
            onChange={handleFileChange}
          />
        </Form.Group>

        <Button type="submit" className="mt-2 button-size" disabled={isLoading}>
          Додајте Корисникове Донације
        </Button>
        <br />
        <br />
        {isLoading && <Loader />}
        {exclelError && <Message variant="danger">{exclelError}</Message>}
      </Form>
    </div>
  );
};

export default ImportDonationForm;
