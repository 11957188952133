import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import { Sidebar } from './components/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  // use userInfo state
  const { adminInfo } = useSelector((state) => state?.adminInfo);

  let urlLogin = window.location.href.search('prijavi-se');

  return (
    <div
      className={urlLogin === 22 ? 'grid-container-login' : 'grid-container'}
    >
      {adminInfo && (
        <>
          <Header OpenSidebar={OpenSidebar} />
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </>
      )}

      <Outlet />
      <ToastContainer />
    </div>
  );
};

export default App;
