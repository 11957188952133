import React from 'react';
import { useAllCustomerCalculationsQuery } from '../../slices/customersApiSlice';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Button, Table } from 'react-bootstrap';

const CalculationScreen = () => {
  const { cus_number: customer_id } = useParams();

  const body = {
    customer_id,
  };

  // API Calls
  const {
    data: allCustomerCalculations,
    isLoading,
    error,
  } = useAllCustomerCalculationsQuery(body);

  return (
    <main className='main-container'>
      <Link to='/korisnici'>
        <Button> Назад</Button>
      </Link>
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Прикупљено</th>
              <th>Преостало</th>
              <th>Искоришћено</th>
              <th>Еуро Укупно</th>
              <th>Еуро Искоришћено</th>
              <th>Еуро Преостало</th>
              <th>УСД Укупно</th>
              <th>УСД Искоришћено</th>
              <th>УСД Преостало</th>
              <th>ЦХФ Укупно</th>
              <th>ЦХФ Искоришћено</th>
              <th>ЦХФ Преостало</th>
              <th>Потребно</th>
              <th>Проценат</th>
            </tr>
          </thead>
          <tbody>
            {allCustomerCalculations?.data && (
              <tr key={allCustomerCalculations?.data.id}>
                <td>
                  {allCustomerCalculations?.data.collected_rsd.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.remaining_rsd.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.utilized_transaction_rsd.toFixed(
                    2
                  )}
                </td>

                <td>
                  {allCustomerCalculations?.data.collected_eur.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.utilized_transaction_eur.toFixed(
                    2
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.remaining_eur.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>

                <td>
                  {allCustomerCalculations?.data.collected_usd.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.remaining_usd.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.utilized_transaction_usd.toFixed(
                    2
                  )}
                </td>

                <td>
                  {allCustomerCalculations?.data.collected_chf.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.utilized_transaction_chf.toFixed(
                    2
                  )}
                </td>
                <td>
                  {allCustomerCalculations?.data.remaining_chf.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>

                <td>
                  {allCustomerCalculations?.data.necessary.toLocaleString(
                    'de-DE',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
                <td>{Math.round(allCustomerCalculations?.data.percentage)}%</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default CalculationScreen;
